// @flow
import classnames from "classnames";
import * as React from "react";
import abstractConfig from "abstract-di/config";
import allSvgs from "./allSvgs";
import style from "./style.scss";

const ICON_SIZE = {
  small: "16px",
  medium: "32px",
  medLarge: "40px",
  large: "48px",
  huge: "120px",
  default: "24px",
};

type Props = {
  containerClassName?: string,
  className: string,
  icon: string,
  innerRef?: React.Ref<"div">,
  size: ?$Keys<typeof ICON_SIZE>,
  inheritFill?: boolean,
  fill?: string,
};

export default function SVGIcon(props: Props) {
  const {
    className,
    containerClassName,
    icon,
    size,
    fill = "#8b8e8f",
    innerRef,
    inheritFill,
    ...rest
  } = props;

  if (!icon) {
    return <div className={className} />;
  }

  const iconSize = size ? ICON_SIZE[size] : ICON_SIZE.default;
  const SVGComponent = allSvgs[`icon-${icon}.svg`];

  if (!SVGComponent) {
    abstractConfig.reportError(
      new Error(`SVG icon ${icon} could not be found in cache`)
    );
  }

  return (
    <div
      ref={innerRef}
      {...rest}
      className={classnames(style.svgContainer, containerClassName)}
    >
      {SVGComponent && (
        <SVGComponent
          className={classnames(className, {
            [style.inheritFill]: inheritFill,
            [style.iconSmall]: size === ICON_SIZE.small,
            [style.iconMedium]: size === ICON_SIZE.medium,
            [style.iconMedLarge]: size === ICON_SIZE.medLarge,
            [style.iconLarge]: size === ICON_SIZE.large,
            [style.iconHuge]: size === ICON_SIZE.huge,
          })}
          fill={fill === "none" ? undefined : fill}
          width={iconSize}
          height={iconSize}
        />
      )}
    </div>
  );
}
