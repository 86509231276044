// @flow
import classnames from "classnames";
import { indexOf } from "lodash";
import * as React from "react";
import Spinner from "core/components/Spinner";
import shallowCompareWithoutFunctions from "core/hocs/shallowCompareWithoutFunctions";
import SVGIcon from "./SVGIcon";
import style from "./style.scss";

export type Props = {
  type: string,
  disabled?: boolean,
  small?: boolean,
  medium?: boolean,
  medLarge?: boolean,
  large?: boolean,
  huge?: boolean,
  tint?: boolean,
  light?: boolean,
  lightGray?: boolean,
  dark?: boolean,
  caution?: boolean,
  warning?: boolean,
  danger?: boolean,
  allgood?: boolean,
  yolk?: boolean,
  className?: string,
  inheritFill?: boolean,
  variation?: "default" | "hover" | "active" | "new",
  innerRef?: React.Ref<"div" | "span">,
};

const colorVariantValues = [
  "tint",
  "dark",
  "light",
  "orange",
  "lightOrange",
  "green",
  "red",
  "lightGray",
];
const fileFormatIcons = ["file-sketch", "file-xd", "file-illustrator"];

export function isFileFormatIcon(icon: string) {
  return (
    fileFormatIcons.filter((format) => {
      return icon && !!icon.match(format);
    }).length > 0
  );
}

function Icon(props: Props) {
  const {
    type,
    disabled,
    small,
    medium,
    medLarge,
    large,
    huge,
    tint,
    light,
    lightGray,
    dark,
    warning,
    caution,
    danger,
    yolk,
    allgood,
    className,
    variation,
    innerRef,
    inheritFill,
    ...rest
  } = props;

  const isSpinner = type === "spinner";
  const isFileFormat = isFileFormatIcon(type);

  if (!isSpinner && !isFileFormat) {
    let icon = type;
    let size = null;
    const iconNameArray = type.split("-");
    const colorVariant = iconNameArray[iconNameArray.length - 1];

    if (small) {
      size = "small";
    } else if (medium) {
      size = "medium";
    } else if (medLarge) {
      size = "medLarge";
    } else if (large) {
      size = "large";
    } else if (huge) {
      size = "huge";
    }

    // for backward compatibility
    const classes = classnames(style.svgIcon, {
      [style.disabled]: disabled,
      [style.iconLightGray]: colorVariant === "lightGray" || lightGray,
      [style.iconTint]: colorVariant === "tint" || tint,
      [style.iconLight]: colorVariant === "light" || light,
      [style.iconWarning]: colorVariant === "orange" || warning,
      [style.iconDanger]: colorVariant === "red" || danger,
      [style.iconAllGood]: colorVariant === "green" || allgood,
      [style.iconYolk]: colorVariant === "yolk" || yolk,
      [style.iconCaution]: colorVariant === "lightOrange" || caution,
      [style.iconSmall]: small,
      [style.iconMedium]: medium,
      [style.iconMedLarge]: medLarge,
      [style.iconLarge]: large,
      [style.iconHuge]: huge,
    });

    // Grabs the root icon name if there's a color variant
    if (indexOf(colorVariantValues, colorVariant) !== -1) {
      icon = iconNameArray.slice(0, iconNameArray.length - 1).join("-");
    }

    return (
      <SVGIcon
        className={classes}
        containerClassName={className}
        icon={icon}
        size={size}
        innerRef={innerRef}
        inheritFill={inheritFill}
        {...rest}
      />
    );
  } else {
    let iconType = type;
    iconType += tint ? "-tint" : "";
    iconType += light ? "-light" : "";
    iconType += variation ? `-${variation}` : "";

    const classes = classnames(className, {
      [style.icon]: true,
      [style[iconType]]: true,
      [style.disabled]: disabled,
    });

    return (
      <span className={classes} ref={innerRef} {...rest}>
        {isSpinner && (
          <Spinner
            className={style.spinnerIcon}
            tint={tint}
            light={light}
            small
          />
        )}
      </span>
    );
  }
}

export default shallowCompareWithoutFunctions<React.ElementConfig<typeof Icon>>(
  Icon
);
