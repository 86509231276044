// @flow
import path from "path";

function importAll(request) {
  return request.keys().reduce((all, key) => {
    all[path.basename(key)] = request(key).default;
    return all;
  }, {});
}

export default importAll(
  // $FlowFixMe: webpack supports require.context
  require.context("./svg", true, /\.svg$/)
);
